var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("div", { staticClass: "attachment" }, [
        _vm._m(0),
        _c("div", { staticClass: "information" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "name",
              attrs: { title: _vm.info.name },
            },
            [_vm._v("\n        " + _vm._s(_vm.info.name) + "\n      ")]
          ),
          _c("div", { staticClass: "filesize" }, [
            _vm.info.size
              ? _c("span", [
                  _vm._v(_vm._s(_vm.bytesToSize(_vm.info.size)) + "  "),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "menu d-print-none" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    size: "sm",
                    "toggle-class": "no-border",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("i", {
                            staticClass: "fa-regular fa-ellipsis clickable",
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-item",
                    { attrs: { href: "#" }, on: { click: _vm.Download } },
                    [_vm._v(_vm._s(_vm.$t("DOWNLOAD")))]
                  ),
                  _vm.filestorage
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.SaveToFilestorage },
                        },
                        [_vm._v(_vm._s(_vm.$t("SAVE_FILESTORAGE")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            scrollable: "",
            "dialog-class": _vm.teams.theme,
            id: "save-attachment-" + _vm.info.name,
            "hide-header": "",
            size: "lg",
            centered: "",
            "ok-disabled": _vm.folder_uuid == "",
            "cancel-title": _vm.$t("CANCEL"),
            "ok-title": _vm.$t("SAVE"),
          },
          on: { cancel: _vm.cancelSave, ok: _vm.okSave },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("ListFolders", {
            model: {
              value: _vm.folder_uuid,
              callback: function ($$v) {
                _vm.folder_uuid = $$v
              },
              expression: "folder_uuid",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon d-print-none" }, [
      _c("i", { staticClass: "fal fa-file" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="mt-2">
    <div class="bodytext text-break mt-4 mb-2" v-if="showContent" v-html="compiledMarkdown"></div>
    <div class="mt-2" v-if="!showContent"><i><small>{{ $t('MISSING_INFORMATION') }}</small></i></div>
  </section>
</template>
<script>
//import { marked } from 'marked'
export default {
  components: {},
  props: ["body"],
  data() {
    return {
    };
  },
  methods: {
    convertTextToLink(text)
    {
      // eslint-disable-next-line
      var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      var text1=text.replace(exp, "<a href='$1'>$1</a>");
      // eslint-disable-next-line
      var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
      return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
    },
  },
  computed: {
    compiledMarkdown: function() {
      //return marked(this.messageContent);
      return this.messageContent;
    },
    messageContent() {
      //return "<html><head><link href='https://"+window.location.hostname+"/css/iframe.css' rel='stylesheet'></head><body>"+this.body+"</body></html>";
      let text =  this.body.trim();
      //text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return this.convertTextToLink(text);
    },
    showContent(){
      return this.messageContent != "" ? true : false;
    }
  },
  mounted() {
  },
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mt-2" }, [
    _vm.showContent
      ? _c("div", {
          staticClass: "bodytext text-break mt-4 mb-2",
          domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
        })
      : _vm._e(),
    !_vm.showContent
      ? _c("div", { staticClass: "mt-2" }, [
          _c("i", [
            _c("small", [_vm._v(_vm._s(_vm.$t("MISSING_INFORMATION")))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
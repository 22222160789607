var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.textOnly
      ? _c(
          "div",
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  label: _vm.$t("RECURRENCE.RECURRENCE"),
                  "label-cols-sm": "2",
                },
              },
              [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c(
                    "div",
                    [
                      _c("b-form-select", {
                        attrs: {
                          disabled: _vm.disabled,
                          options: _vm.frequencyOptions,
                          "aria-label": _vm.$t(
                            "NARR.RECURRENCE.SELECT_RECURRENCE_FREQUENCY"
                          ),
                        },
                        on: { change: _vm.ChangedFrequency },
                        model: {
                          value: _vm.selectedFrequency,
                          callback: function ($$v) {
                            _vm.selectedFrequency = $$v
                          },
                          expression: "selectedFrequency",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.selectedFrequency != "None" && !_vm.disabled
                        ? _c(
                            "b-button",
                            {
                              staticClass: "no-border ml-1",
                              attrs: { variant: "primary" },
                              on: { click: _vm.OpenDialog },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("RECURRENCE.CUSTOMIZE")) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "custom-modal",
                  "dialog-class": _vm.teams.theme,
                  "hide-header": "",
                  centered: "",
                  "cancel-title": _vm.$t("CANCEL"),
                  "ok-disabled": !_vm.saveDisabled,
                },
                on: { ok: _vm.Save, cancel: _vm.Cancel },
                model: {
                  value: _vm.showCustomModal,
                  callback: function ($$v) {
                    _vm.showCustomModal = $$v
                  },
                  expression: "showCustomModal",
                },
              },
              [
                _vm.RecurrenceDTO
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          key:
                            "recurrenceKey_" +
                            _vm.RecurrenceDTO.frequency +
                            _vm.customFrequencyOptions,
                        },
                        [
                          _c("b-form-group", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("RECURRENCE.REPETITION"))),
                            ]),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.$t("RECURRENCE.EVERY"))),
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "recurrence-input-wrapper": true,
                                        "is-invalid": !_vm.isValidInterval,
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "999",
                                          "aria-label": _vm.$t(
                                            "NARR.RECURRENCE.INPUT_INTERVAL"
                                          ),
                                        },
                                        on: { change: _vm.CheckCustom },
                                        model: {
                                          value:
                                            _vm.RecurrenceDTO.everyInterval,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.RecurrenceDTO,
                                              "everyInterval",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "RecurrenceDTO.everyInterval",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("b-form-select", {
                                  staticClass: "ml-2",
                                  attrs: {
                                    options: _vm.customFrequencyOptions,
                                    "aria-label": _vm.$t(
                                      "NARR.RECURRENCE.SELECT_RECURRENCE_FREQUENCY"
                                    ),
                                  },
                                  on: { change: _vm.ChangedCustomFrequency },
                                  model: {
                                    value: _vm.RecurrenceDTO.frequency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.RecurrenceDTO,
                                        "frequency",
                                        $$v
                                      )
                                    },
                                    expression: "RecurrenceDTO.frequency",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.RecurrenceDTO.frequency == "Weekly"
                            ? _c(
                                "div",
                                { staticClass: "mt-3" },
                                [
                                  _c("b-form-group", [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("RECURRENCE.DAYS_OF_THE_WEEK")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "recurrence-days-button-group",
                                      },
                                      _vm._l(
                                        _vm.daysOfWeekOptions,
                                        function (day) {
                                          return _c(
                                            "b-button",
                                            {
                                              key: day.value,
                                              staticClass:
                                                "recurrence-day-button",
                                              attrs: {
                                                variant: _vm.isDaySelected(
                                                  day.value
                                                )
                                                  ? "primary"
                                                  : "outline-primary",
                                                "aria-label": `${day.text}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleDay(
                                                    day.value
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(day.text.charAt(0)) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-form-group",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  attrs: {
                                    "aria-label": _vm.$t(
                                      "NARR.RECURRENCE.SELECT_END_TYPE"
                                    ),
                                  },
                                  on: { change: _vm.ChangedEndType },
                                  model: {
                                    value: _vm.endType,
                                    callback: function ($$v) {
                                      _vm.endType = $$v
                                    },
                                    expression: "endType",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: {
                                        value: "untilDate",
                                        "aria-label": _vm.$t(
                                          "RECURRENCE.UNTIL_DATE"
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("RECURRENCE.UNTIL_DATE")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: {
                                        value: "occurrences",
                                        "aria-label": _vm.$t(
                                          "NARR.RECURRENCE.SELECT_OCCURRENCES"
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("RECURRENCE.OCCURRENCES")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.endType === "untilDate"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("PickDate", {
                                        on: {
                                          change: _vm.CheckCustom,
                                          valid: (n) =>
                                            (_vm.validUntilDate = n),
                                        },
                                        model: {
                                          value: _vm.RecurrenceDTO.untilDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.RecurrenceDTO,
                                              "untilDate",
                                              $$v
                                            )
                                          },
                                          expression: "RecurrenceDTO.untilDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.endType === "occurrences"
                            ? _c(
                                "div",
                                [
                                  _c("b-form-group", [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          "recurrence-input-wrapper": true,
                                          "is-invalid": !_vm.isValidOccurrences,
                                        },
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass:
                                            "recurrence-bordered-input recurrence-form-control",
                                          attrs: {
                                            type: "number",
                                            min: "2",
                                            "aria-label": _vm.$t(
                                              "NARR.RECURRENCE.INPUT_NUMBER_OF_OCCURRENCES"
                                            ),
                                          },
                                          on: { change: _vm.CheckCustom },
                                          model: {
                                            value:
                                              _vm.RecurrenceDTO.occurrences,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.RecurrenceDTO,
                                                "occurrences",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "RecurrenceDTO.occurrences",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.RecurrenceDTO.frequency !== "None" &&
                          _vm.RecurrenceDTO.everyInterval
                            ? _c("div", { staticClass: "mt-3" }, [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.recurrenceSummary)),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.textOnly
      ? _c("div", [_vm._v(_vm._s(_vm.recurrenceSummary))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }